import React from "react";
import { getOldBatchTypeFromOldComicType, OldComicType } from "../../common";

type Props = {
  batches: IBatch[],
  mode: OldComicType,
  batchFilter: string,
  setBatchFilter: (batchFilter: string) => void,
}

const BatchFilter: React.FC<Props> = ({ batches, mode, batchFilter, setBatchFilter }) => {

  const batchesFiltered = [...new Set<IBatch>(batches?.filter(obj => obj.type === getOldBatchTypeFromOldComicType(mode)))];

  return (
    <span className='link-small'>
      {batchesFiltered.map(c => (<span key={c.name}>&nbsp;<button onClick={() => setBatchFilter(c.name)} className={'link-button' + (c.name === batchFilter ? ' bold' : '')}>{c.name}</button>&nbsp;•&nbsp;</span>))}
      <button onClick={() => setBatchFilter('all')} className={'link-button' + ('all' === batchFilter ? ' bold' : '')}>All</button>
    </span>)
}

export default BatchFilter;