import React, { useState } from 'react';
import { getImageIdUrl, getImageUrl } from '../../utils/ImageHandler';
import './ImageViewer.css'

type Props = {
  comic: IComic,
  showSelectedImage: (index: number) => void,
}

const ImageViewer: React.FC<Props> = ({ comic, showSelectedImage }) => {

  const [selected, setSelected] = useState(0); // 0 = main image

  const handleSelectedImage = (index: number) => {
    setSelected(index);
    showSelectedImage(index);
  }

  return (
    <div className='image-viewer-wrapper'>
        <div className='image-viewer'>
          <div className="image-viewer-image-wrapper">
            <img className={'image-viewer-image' + (selected === 0 ? ' image-viewer-image-selected' : '')} alt='comic 1' src={getImageUrl(comic)} onClick={() => handleSelectedImage(0)} />
          </div>
          {comic.images.map((image, idx) => image ? (
            <div className="image-viewer-image-wrapper" key={idx}>
              {image.imageId &&
                <img className={'image-viewer-image' + (selected === (idx+1)? ' image-viewer-image-selected' : '')} alt={'comic ' + (idx + 2)} src={getImageIdUrl(comic, image.imageId)} onClick={() => handleSelectedImage(idx + 1)} />}
            </div>
          ) : (<></>))}
        </div>
    </div>
  );
};

export default ImageViewer;