export const USER_STORED_SEARCHES = 5;

export enum ItemType {
  COMIC = 'comic',
  GAME = 'game',
  ACCESSORY = 'accessory',
}

export enum PeriodType {
  NEW = 'new',
  BACK_ISSUE = 'back-issue',
  PRE_ORDER = 'pre-order',
  VINTAGE = 'vintage',
}

export enum SupplierType {
  DIAMOND = 'diamond',
  // LUNAR
  LPG = 'lpg',
  AUCTION = 'auction',
  LOCAL_SHOP = 'local_shop',
  LOCAL_PERSON = 'local_person',
}

// >> combos
// COMIC.NEW.DIAMOND (NEW)
// COMIC.PRE_ORDER.DIAMOND (PRE)
// COMIC.BACK_ISSUE.AUCTION (BACK)
// COMIC.BACK_ISSUE.LOCAL_SHOP (!)
// COMIC.BACK_ISSUE.LOCAL_PERSON (!)
// GAME.NEW.LPG (GAME)
// GAME.VINTAGE.AUCTION (!)
// ACCESSORY.NEW.LPG (ACCESSORY)

// >> menu options
// COMIC.NEW (NEW)
// COMIC.PRE_ORDER (PRE)
// COMIC.BACK_ISSUE (BACK)
// GAME.NEW (GAME)
// GAME.VINTAGE (!)
// ACCESSORY.NEW (ACCESSORY)


// new methods ------------------------------------------------------

export const getItemTypeName = (ct: ItemType) => {
  switch (ct) {
    case ItemType.COMIC: { return 'Comics'; }
    case ItemType.GAME: { return 'Games'; }
    case ItemType.ACCESSORY: { return 'Accessories'; }
  }
}

export const getPeriodTypeName = (pt: PeriodType) => {
  switch (pt) {
    case PeriodType.NEW: { return 'New'; }
    case PeriodType.BACK_ISSUE: { return 'Back-Issue'; }
    case PeriodType.PRE_ORDER: { return 'Pre-Order'; }
    case PeriodType.VINTAGE: { return 'Vintage'; }
  }
}

export const getSupplierTypeName = (pt: SupplierType) => {
  switch (pt) {
    case SupplierType.DIAMOND: { return 'Diamond'; }
    case SupplierType.LPG: { return "Let's Play Games"; }
    case SupplierType.AUCTION: { return 'Auction'; }
    case SupplierType.LOCAL_SHOP: { return 'Local Shop'; }
    case SupplierType.LOCAL_PERSON: { return 'Local Person'; }
  }
}


// old definitions ------------------------------------------------------------------------------

export enum OldComicType {
  NEW = 'new',
  BACK = 'back',
  PRE = 'pre',
  GAME = 'game',
  ACCESSORY = 'accessory',
}

export enum OldBatchType {
  DIAMOND = 'diamond',
  BACK_ISSUE = 'back',
  PRE = 'pre',
  GAME = 'game',
}


// converters -------------------------------

export const getItemType = (comic: IComic): ItemType => {
  switch (comic.type) {
    case OldComicType.BACK: { return ItemType.COMIC }
    case OldComicType.PRE: { return ItemType.COMIC }
    case OldComicType.GAME: { return ItemType.GAME }
    case OldComicType.ACCESSORY: { return ItemType.COMIC }
    default: { return ItemType.COMIC } // OldComicType.NEW
  }
}

export const getPeriodType = (comic: IComic): PeriodType => {
  switch (comic.type) {
    case OldComicType.BACK: { return PeriodType.BACK_ISSUE }
    case OldComicType.PRE: { return PeriodType.NEW }
    case OldComicType.GAME: { return PeriodType.NEW }
    case OldComicType.ACCESSORY: { return PeriodType.NEW }
    default: { return PeriodType.NEW } // OldComicType.NEW
  }
}

export const getSupplierType = (batch: IBatch): SupplierType => {
  switch (batch.type) {
    case OldBatchType.BACK_ISSUE: { return SupplierType.AUCTION }
    case OldBatchType.DIAMOND: { return SupplierType.DIAMOND }
    case OldBatchType.PRE: { return SupplierType.DIAMOND }
    default: { return SupplierType.LPG } // OldBatchType.GAME
  }
}



// old methods ----------------------------

export const getOldComicTypeName = (ct: OldComicType) => {
  switch (ct) {
    case OldComicType.BACK: { return 'Back Issues'; }
    case OldComicType.PRE: { return 'Pre-orders'; }
    case OldComicType.GAME: { return 'Games'; }
    case OldComicType.ACCESSORY: { return 'Accessories'; }
    default: { return 'New Stocked Comics'; }
  }
}

export const getOldBatchTypeFromOldComicType = (ct: OldComicType) => {
  switch (ct) {
    case OldComicType.BACK: { return OldBatchType.BACK_ISSUE; }
    case OldComicType.PRE: { return OldBatchType.PRE; }
    case OldComicType.GAME: { return OldBatchType.GAME; }
    case OldComicType.ACCESSORY: { return OldBatchType.GAME; }
    default: { return OldBatchType.DIAMOND; }
  }
}

export const getURLFromOldComicType = (ct: OldComicType | undefined) => {
  switch (ct) {
    case OldComicType.BACK: { return '/back-issues'; }
    case OldComicType.PRE: { return '/pre-orders'; }
    case OldComicType.GAME: { return '/games'; }
    case OldComicType.ACCESSORY: { return '/accessories'; }
    default: { return '/'; }
  }
}