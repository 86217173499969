import React, { useState } from 'react'
import { OldComicType } from '../../common'
import { formatMainDescriptionComic, formatPublisherComic, formatVariantDescriptionComic } from '../../utils/OrderFormats'

import AddAccessoryItem from './AddAccessoryItem'
import AddBackIssueItem from './AddBackIssueItem'
import AddGameItem from './AddGameItem'
import AddNewStockItem from './AddNewStockItem'
import AddPreOrderItem from './AddPreOrderItem'

import ViewAccessoryItem from './ViewAccessoryItem'
import ViewBackIssueItem from './ViewBackIssueItem'
import ViewGameItem from './ViewGameItem'
import ViewNewStockItem from './ViewNewStockItem'
import ViewPreOrderItem from './ViewPreOrderItem'

import './ViewEditStockItemSwitcher.css';

type Props = ComicProps & {
  batches: IBatch[] | undefined,
  updateStock: (comic: IComic) => void,
  deleteStock: (comic: IComic) => void,
  addStock?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic) => void,
}

const formatFileName = (thisName: string) => {
  return thisName.substring(0, thisName.indexOf('.'));
}

export const handleImageDrop = (
  e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: { name: any }[]; clearData: () => void } },
  setFormComic: React.Dispatch<React.SetStateAction<IComic>>,
  formComic: IComic) => {

  const numberOfFiles = e.dataTransfer.files.length;
  console.log('numberOfFiles ' + numberOfFiles);
  if (numberOfFiles > 1) { // when more than one file, clear all and add them

    let unsortedNames: string[] = []
    for (let n = 0; n < e.dataTransfer.files.length; n++) {
      let thisName = e.dataTransfer.files[n].name
      unsortedNames[n] = formatFileName(thisName);
    }
    console.log(unsortedNames)
    let names = unsortedNames.sort()

    let images: ImageType[] = []
    for (let n = 1; n < names.length; n++) {
      images[n] = { imageId: names[n] }
    }

    let comicId = names[0]

    setFormComic({ ...formComic, comicId, images })
    e.dataTransfer.clearData()
  }
  else if (numberOfFiles === 1) { // when one file

    let thisName = formatFileName(e.dataTransfer.files[0].name);

    // check if a duplicate and if so, clear and add
    if (formComic.comicId === thisName || formComic.images.filter((image) => image.imageId === thisName).length > 0) {
      const comicId = thisName;
      const images: ImageType[] = [];
      setFormComic({ ...formComic, comicId, images })
    }
    else { // else just add
      console.log('formComic.comicId ' + formComic.comicId);

      if (formComic.comicId === undefined || formComic.comicId === '') {
        console.log('comicId');
        const comicId = thisName;
        setFormComic({ ...formComic, comicId })
      }
      else {
        console.log('images');
        const images = formComic.images.concat([{ imageId: thisName }]);
        setFormComic({ ...formComic, images })
      }
    }
  }
}

/* this item is a switcher - it switches from view to edit mode */
const ViewEditStockItemSwitcher: React.FC<Props> = ({ comic, batches, updateStock, deleteStock, addStock }) => {

  const [mode, setMode] = useState('view');
  const [formComic, setFormComic] = useState<IComic>(comic);

  // drag and drop stuff
  const [drag, setDrag] = React.useState(false);
  let dropRef = React.useRef<HTMLDivElement>(null);
  let dragCounter = 0;

  React.useEffect(() => {
    let div: any = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  });

  const handleDrag = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { items: string | any[]; }; }) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) setDrag(false);
  };

  const handleDrop = (e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: { name: any; }[]; clearData: () => void; }; }) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageDrop(e, setFormComic, formComic)
      dragCounter = 0;
    }
  };

  // end drag and drop stuff

  const handleEditMode = () => {
    setFormComic({
      ...comic,
      mainDescription: formatMainDescriptionComic(comic),
      variantDescription: formatVariantDescriptionComic(comic),
      publisher: formatPublisherComic(comic),
    })
    setMode('edit');
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateStock && updateStock(formComic);
    setMode('view');
  }

  const handleCancel = () => {
    setFormComic(comic);
    setMode('view');
  }

  return (
    <div ref={dropRef} className={drag ? 'filedrop drag' : 'filedrop ready'}>
      {mode === 'edit' && formComic.type === OldComicType.NEW ?
        (<AddNewStockItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
        mode === 'view' && formComic.type === OldComicType.NEW ?
          <ViewNewStockItem comic={comic} formComic={comic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode} /> :
          mode === 'edit' && formComic.type === OldComicType.GAME ?
            (<AddGameItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
            mode === 'view' && formComic.type === OldComicType.GAME ?
              <ViewGameItem comic={comic} formComic={comic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode} /> :
              mode === 'edit' && formComic.type === OldComicType.ACCESSORY ?
                (<AddAccessoryItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
                mode === 'view' && formComic.type === OldComicType.ACCESSORY ?
                  <ViewAccessoryItem comic={comic} formComic={comic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode} /> :
                  mode === 'edit' && formComic.type === OldComicType.PRE ?
                    (<AddPreOrderItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
                    mode === 'view' && formComic.type === OldComicType.PRE ?
                      <ViewPreOrderItem comic={comic} formComic={comic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode} /> :
                      mode === 'edit' && formComic.type === OldComicType.BACK ?
                        (<AddBackIssueItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
                        // mode === 'view' && formComic.type === ComicType.BACK ?
                        <ViewBackIssueItem formComic={comic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode}/>}
      <hr />
    </div>
  )
}

export default ViewEditStockItemSwitcher

