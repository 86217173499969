import React from 'react';
import { formatMoney } from '../../utils/MoneyFormats';
import { formatOrderName } from '../../utils/OrderFormats';
import './OrderListItem.css';
import { getOrderItemUrl } from '../../utils/ImageHandler';
import { OldComicType } from '../../common';

type Props = OrderItemProps & {
  firstOrderItemRef: any,
  orderItemIndex: number,
  increaseOrderItem: (orderItem: IOrderItem) => void,
  decreaseOrderItem: (orderItem: IOrderItem) => void,
  deleteOrderItem: (orderItem: IOrderItem) => void,
}

const OrderListItem: React.FC<Props> = ({ firstOrderItemRef, orderItemIndex, orderItem, increaseOrderItem, decreaseOrderItem, deleteOrderItem }) => {

  const comicPrice = formatMoney(orderItem.price);
  const imgCss = orderItem.type && orderItem.type === OldComicType.GAME ? 'comic-item-preview-cover-game' : 'comic-item-preview-cover';
  const thisOrderItemRef = orderItemIndex === 0 ? firstOrderItemRef : undefined;

  return (
    <div>
      <div className='order-item' ref={thisOrderItemRef}>
        <div className='row'>
          <div className="comic-item-preview-holder"><img className={imgCss} alt='cover' src={getOrderItemUrl(orderItem)} /></div>
          <div className='comic-delete-button-wrapper'>
            <button
              onClick={() => deleteOrderItem(orderItem)}
              className='image-button comic-delete-button'
            >
              <img src='/img/x.svg' alt='delete' />
            </button>
          </div>

          <div className='order-item-names'>
            <div className='order-item-name'>{formatOrderName(orderItem.name)}</div>
          </div>

          {orderItem.available > 1 ?
            (<div className='order-item-plus-minus'>
              <button
                onClick={() => increaseOrderItem(orderItem)}
                className='plus-button'
                disabled={!(orderItem.available - orderItem.quantity > 0)}
              >
                <span className='plus-text'>
                +
                </span>
              </button>
              <button
                onClick={() => decreaseOrderItem(orderItem)}
                className='minus-button'
              >
                <span className='minus-text'>
                -
                </span>
              </button>
            </div>) : ''}
          {orderItem.quantity > 1 ? 
            (<div className='order-item-summary'><span className='order-item-quantity-x'>x</span><span className='order-item-quantity-number'>{orderItem.quantity}</span></div>) : ''}
          <div className='comic-item-price-ordered'>
            <span className='big-price'>${comicPrice.substring(0, comicPrice.indexOf('.'))}</span>
            <span className='little-price'>{comicPrice.substring(comicPrice.indexOf('.'))}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderListItem

