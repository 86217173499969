import React, { useEffect, useRef, useState } from 'react'
import OrderListComic from './OrderListComic'
import { completeOrder, getCurrentOrder, getStocks, searchStocks, updateOrder } from '../../API'
import CurrentOrder from './CurrentOrder'
import './OrderList.css';
import PaymentDialog from './PaymentDialog';
import swal from 'sweetalert2';
import { getPriceComicB } from '../../utils/MoneyFormats';
import { updateOrderState } from '../../utils/OrderCalculations';
import { OldComicType, getOldComicTypeName, getURLFromOldComicType, USER_STORED_SEARCHES } from '../../common';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { State } from '../../App';
import { getAdminFromStorage } from '../../utils/UserHandler';
import BatchFilter from '../general/BatchFilter';

type Props = {
  state: State,
  setState: any,
  order: IOrder,
  setOrder: any,
  libraries: string[],
  batches: IBatch[],
}

const OrderList: React.FC<Props> = ({ state, setState, order, setOrder, libraries, batches }) => {
  const articleRef = useRef<HTMLDivElement>(null);
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [stock, setStock] = useState<IComic[]>([]);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [credit, setCredit] = useState(0);
  const [batchFilter, setBatchFilter] = useState('all');
  const [articleScrollTop, setArticleScrollTop] = useState(0);
  const [pagination, setPagination] = useState({
    isLoading: false,
    page: 1,
    lastMode: state.mode,
    lastPage: 0,
    lastFilter: 'all',
    stockCache: [] as IComic[],
  });
  const firstOrderItemRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentOrder();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchStock();
  }, [state.mode, state.searchTerm, pagination.page, batchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    const articleDiv = articleRef.current;

    if (state.searchTerm === '') {
      // console.log('setting scrolltop');
      setArticleScrollTop(articleDiv ? articleDiv.scrollTop : 0);
    }

    // Check if scrolled to the bottom of the content div
    if (
      state.searchTerm === '' &&
      articleDiv &&
      !pagination.isLoading &&
      articleDiv.scrollTop + articleDiv.clientHeight + 100 >= articleDiv.scrollHeight
    ) {
      // Trigger the scroll event in the parent component
      setPagination({ ...pagination, page: ++pagination.page, isLoading: true });
    }
  };

  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    transactions = transactions ? transactions : [];
    setTransactions(transactions);
    credit = credit ? credit : 0;
    setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
    setOrderItems(order.orderItems);
    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const fetchCurrentOrder = () => {
    // console.log(`fetchCurrentOrder()`);
    getCurrentOrder()
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err: Error) => console.log(err));
  }

  const fetchStock = () => {

    // console.log('fetchStock() ' + batchFilter);

    if (state.searchTerm) {

      setPagination({ ...pagination, isLoading: true, stockCache: stock });

      // legacy
      if (order && !getAdminFromStorage()) {
        order.searchTerms += (order.searchTerms === '' ? state.searchTerm : ' | ' + state.searchTerm);
      }

      if (order) {
        // is term in history
        if (order.searchHistory.indexOf(state.searchTerm) >= 0) {
          // shift to top
          order.searchHistory = order.searchHistory.filter(item => item !== state.searchTerm);
          order.searchHistory.unshift(state.searchTerm);
        }
        else {
          // add at start and delete last entry
          order.searchHistory.unshift(state.searchTerm);
          if (order.searchHistory.length > USER_STORED_SEARCHES) {
            order.searchHistory.pop();
          }
        }

        // console.log(' order.searchHistory = ' + order.searchHistory);

        setOrder(order);
      }

      searchStocks(state.searchTerm)
        .then(({ data: { comics } }: IComic[] | any) => setStock(comics))
        .catch((err: Error) => console.log(err))

      setPagination({ ...pagination, isLoading: false });
      setTimeout(() => {
        if (articleRef && articleRef.current) {
          articleRef.current.scrollTop = 0;
        }
      }, 50);
    }
    else {

      // console.log('cache page = ' + pagination.page + '  lastPage= ' + pagination.lastPage + '  lastFilter=' + pagination.lastFilter);
      if (pagination.page === pagination.lastPage && pagination.lastMode === state.mode && pagination.lastFilter === batchFilter) {

        // console.log('reloading cache page');
        setStock(pagination.stockCache);
        setTimeout(() => {
          if (articleRef && articleRef.current) {
            articleRef.current.scrollTop = articleScrollTop;
            // console.log("set scroll good " + articleScrollTop);
          }
          else {
            // console.log("set scroll fail " + articleScrollTop);
          }
        }, 50);
      }
      else {

        if (pagination.lastMode !== state.mode) {
          // console.log('mode switch: reloading cache');
          pagination.page = 1;
        }
        else {
          // console.log('adding to cache');
        }

        // console.log('getStocks() ' + batchFilter);

        getStocks(state.mode, (pagination.page === 1 ? 120 : 60), pagination.page, batchFilter)
          .then(({ data: { comics } }: IComic[] | any) => {
            // console.log('newStock !!');
            let newStock = pagination.lastMode === state.mode && pagination.lastFilter === batchFilter ? [...pagination.stockCache, ...comics] : comics;
            // console.log('newStock = ' + newStock.length);
            setStock(newStock);
            setPagination({ ...pagination, isLoading: false, lastPage: pagination.page, lastMode: state.mode, lastFilter: batchFilter, stockCache: newStock });
          })
          .catch((err: Error) => console.log(err))
      }
    }
  }

  const handleAddComicDiv = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, comic: IComic): void => {
    e.stopPropagation();
    handleAddComic(comic);
  }

  const handleAddComic = (comic: IComic): void => {
    // console.log(comic);

    const orderItem = orderItems.find((i) => i.comicId === comic.comicId);
    if (orderItem && orderItem !== undefined) {
      // update
      orderItems.splice(orderItems.indexOf(orderItem), 1);
      orderItem.quantity++;

      // add at top of array
      const newOrderItems = [orderItem, ...orderItems,];
      order.orderItems = newOrderItems;
    }
    else {
      // add
      const price = getPriceComicB(comic, comic.batch);
      //console.log(`comic.comicId ` + comic.comicId + ' price = ' + price)
      const newOrderItems = [{
        name: comic.mainDescription,
        type: comic.type,
        available: comic.number,
        quantity: 1,
        price: price,
        comicId: comic.comicId,
        pnp: comic.pnp ? comic.pnp : 0
      }, ...orderItems,];
      order.orderItems = newOrderItems;
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
  }

  const handClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  }

  const handleLogin = () => {
    setPaymentDialogOpen(false);
    setState({ ...state, loginOpen: true })
  }

  // COPY AND PASTE THIS, BECAUSE REACT IS SHIT
  const handleSearch = (searchTermInput: string) => {
    if (searchTermInput === '') {
      navigate(state.lastMode === OldComicType.NEW ? '/' : state.lastMode === OldComicType.BACK ? '/back-issues' : '/pre-orders')
      setState({ ...state, searchTerm: searchTermInput, lastMode: undefined });
    }
    else {
      if (!state.lastMode) {
        setState({ ...state, searchTerm: searchTermInput, lastMode: state.mode });
      }
      else {
        setState({ ...state, searchTerm: searchTermInput });
      }
      navigate("/search");
    }
  }

  const cardTokenizeResponseReceived = (token: any, verifiedBuyer: any) => {

    // disabled button
    // const squareButton:any = document.getElementById('rswp-card-button');
    // if (squareButton) {
    //   squareButton.disabled = 'true';
    // }

    const cardToken = token.token !== undefined ? token?.token : "";
    const buyerToken = verifiedBuyer?.token !== undefined ? verifiedBuyer?.token : "";
    // console.info('Token:', cardToken);
    // console.info('Verified Buyer:', buyerToken);
    const parsedFloat = (order.toPay).toFixed(0);
    // console.log('parsedFloat=' + parsedFloat);
    // add
    const newTransactions = {
      value: parsedFloat,
      cardToken: cardToken,
      buyerToken: buyerToken,
      complete: false,
    };
    order.latestTransaction = newTransactions;
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    completeOrder(order)
      .then(({ status, data: { order, transactions, credit, errorMessage } }: any) => {

        // disabled button
        // const squareButton:any = document.getElementById('rswp-card-button');
        // if (squareButton) {
        //   squareButton.disabled = 'true';
        // }


        // console.log(status);
        if (status === 200) {
          update(order, transactions, credit);
          swal.fire("Success", "success", "success").then(() => {

            setPaymentDialogOpen(false);
            window.location.reload();
          });
        }
        else {
          swal.fire("Failure", errorMessage, 'error').then(() => {

            setPaymentDialogOpen(false);
            window.location.reload();
          });
        }
      })
      .catch(function (error: any) {
        console.log('error1 = ' + error.response);
        // console.log('error2 = ' + error.response.data);
        // console.log('error3 = ' + error.response.data.errorMessage);
        swal.fire("Failure", 'fail', 'error');
      })
  }

  const handleCompleteOrder = (): void => {

    order.latestTransaction = undefined;
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    completeOrder(order)
      .then(({ status, data: { order, transactions, credit, errorMessage } }: any) => {
        // console.log(status);
        if (status === 200) {
          update(order, transactions, credit);
          swal.fire("Success", "success", "success").then(() => {

            setPaymentDialogOpen(false);
            window.location.reload();
          });
        }
        else {
          swal.fire("Failure", errorMessage, 'error').then(() => {

            setPaymentDialogOpen(false);
            window.location.reload();
          });
        }
      })
      .catch(function (error: any) {
        console.log('error1 = ' + error.response);
        // console.log('error2 = ' + error.response.data);
        // console.log('error3 = ' + error.response.data.errorMessage);
        swal.fire("Failure", 'fail', 'error');
      })
  }

  const clearSearchTerm = () => {
    setState({ ...state, searchTerm: '', lastMode: undefined });
    navigate(getURLFromOldComicType(state.lastMode));
  }

  // const searchResults = stock && stock.length > 0 && state.searchTerm ? stock.filter(comic => comic.mainDescription.toLocaleLowerCase().indexOf(state.searchTerm.trim().toLocaleLowerCase()) > -1) : [];

  // console.log('state.mode ' + state.mode);
  return (
    <>
      <Helmet>
        <title>Isle of Avalon Comics - {state.searchTerm !== '' ? 'Search' : getOldComicTypeName(state.mode)}</title>
      </Helmet>

      {paymentDialogOpen ? (
        <PaymentDialog
          order={order}
          setOrder={setOrder}
          libraries={libraries}
          transactions={transactions}
          credit={credit}
          handleCloseDialog={handClosePaymentDialog}
          handleLogin={handleLogin}
          cardTokenizeResponseReceived={cardTokenizeResponseReceived}
          handleCompleteOrder={handleCompleteOrder}
        />
      ) : ""}
      <article ref={articleRef} onScroll={handleScroll}>
        <h1>
          {state.searchTerm !== '' ? 'Search Results for "' + state.searchTerm + '"' : getOldComicTypeName(state.mode)}
          {(state.searchTerm === '' && getAdminFromStorage()) &&
            <BatchFilter batches={batches} mode={state.mode} batchFilter={batchFilter} setBatchFilter={setBatchFilter} />}
        </h1>
        {state.searchTerm !== '' ? (<><h4 className='inline'>{stock.length} results</h4><button className='link-button' onClick={clearSearchTerm}>clear</button></>) : ""}
        <div className='comic-list'>
          {stock !== undefined ?
            stock.map((comicItem) => (
              <OrderListComic
                key={stock.indexOf(comicItem)}
                comic={comicItem}
                orderItems={orderItems}
                addComic={handleAddComicDiv}
                handleSearch={handleSearch}
              />
            ))
            :
            <div>no comic items</div>
          }
          {pagination.isLoading && <div><b>Loading...</b></div>}
        </div>
      </article>
      <aside>
        <CurrentOrder
          order={order}
          orderItems={orderItems}
          setOrder={setOrder}
          state={state}
          setState={setState}
          firstOrderItemRef={firstOrderItemRef}
          update={update}
          handleEbayCashOrder={handleCompleteOrder}
          handleOpenPaymentDialog={handleOpenPaymentDialog}
          isMobileOrder={false}
        />
        {state.showMobileOrder ?
          (<div className='mobile-order-list'>
            <CurrentOrder
              order={order}
              orderItems={orderItems}
              setOrder={setOrder}
              state={state}
              setState={setState}
              firstOrderItemRef={firstOrderItemRef}
              update={update}
              handleEbayCashOrder={handleCompleteOrder}
              handleOpenPaymentDialog={handleOpenPaymentDialog}
              isMobileOrder={true}
            />
          </div>) : ''}
      </aside>
    </>
  )
}

export default OrderList
