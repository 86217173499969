import React, { useEffect, useState } from 'react'
import ViewEditStockItemSwitcher from './ViewEditStockItemSwitcher'
import { deleteStock, getStocksAdmin, updateStock } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import { getPriceComicB } from '../../utils/MoneyFormats'
import Swal from 'sweetalert2'
import { formatMoney } from '../../utils/MoneyFormats'
import './AllStockList.css'
import { OldComicType, getOldComicTypeName, getOldBatchTypeFromOldComicType } from '../../common'
import BatchFilter from '../general/BatchFilter'

type Props = {
  comicType: OldComicType,
  batches: IBatch[],
}

const AdminStockList: React.FC<Props> = ({ comicType, batches }) => {

  const findBatch = (batches: IBatch[]) => {
    const batch = batches.find(batch => batch.type === getOldBatchTypeFromOldComicType(comicType));
    //console.log(`findBatch() ` + batch?.name + ' for ' + comicType);
    return batch ? batch.name : 'all';
  }

  const [stock, setStocks] = useState<IComic[]>([])
  const [batchFilter, setBatchFilter] = useState(findBatch(batches));
  //  const [secondTime, setSectionTime] = useState(false);

  const fetchStocks = (batchFilter: string): void => {
    // console.log(`fetchStocks() ` + batchFilter);
    getStocksAdmin(comicType, 2000, 1, batchFilter)
      .then(({ data: { comics } }: IComic[] | any) => setStocks(comics))
      .catch((err: Error) => console.log(err))
  }

  useEffect(() => {
    // console.log(`comicType has changed`);
    setBatchFilter(findBatch(batches));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comicType])

  useEffect(() => {
    fetchStocks(batchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchFilter])

  const handleUpdateStock = (comic: IComic): void => {
    // console.log(comic);
    updateStock(comic)
      .then(({ status }) => {
        if (status !== 200) {
          throw new Error('Error! Stock not updated')
        }
        fetchStocks(batchFilter);
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteStock = (comic: IComic): void => {
    if (comic._id !== undefined) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (comic._id !== undefined) {
            deleteStock(comic._id)
              .then(({ status }) => {
                if (status !== 200) {
                  throw new Error('Error! Stock not updated')
                }
                fetchStocks(batchFilter);

                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              })
              .catch((err) => console.log(err))
            Swal.fire(
              'NOT Deleted!',
              'Your file was NOT deleted.',
              'error'
            )
          }
        }
      })
    }
  }

  return (

    <>
      <article>
        <h1>
          {getOldComicTypeName(comicType)} ({stock.length} unique,&nbsp;
          {stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number : 0), 0)} total,
          ${formatMoney(stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number * getPriceComicB(comic, comic.batch) : 0), 0))})
        </h1>
        <div className='link-small'>
          filter: <BatchFilter batches={batches} mode={comicType} batchFilter={batchFilter} setBatchFilter={setBatchFilter} />
        </div>
        <br />
        {stock && stock.map((comic: IComic) => (
          <ViewEditStockItemSwitcher
            key={comic._id}
            batches={batches}
            updateStock={handleUpdateStock}
            deleteStock={handleDeleteStock}
            comic={comic}
          />
        ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AdminStockList
