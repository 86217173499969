import React, { useEffect, useState } from 'react'
import ViewEditStockItemSwitcher from './ViewEditStockItemSwitcher'
// import AddStock from './AddStock'
import { deleteStock, getStocksAdmin, updateStock } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import { getPriceComicB } from '../../utils/MoneyFormats'
import Swal from 'sweetalert2'
import { formatMoney } from '../../utils/MoneyFormats'
import './AllStockList.css'
import { Helmet } from 'react-helmet'

type Props = {
  batches: IBatch[],
}

const AllStockList: React.FC<Props> = ({batches}) => {
  const [stock, setStocks] = useState<IComic[]>([])

  useEffect(() => {
    fetchStocks()
  }, [])

  const fetchStocks = (): void => {
    // console.log(`fetchStocks()`);
    getStocksAdmin(undefined, 9999)
      .then(({ data: { comics } }: IComic[] | any) => setStocks(comics))
      .catch((err: Error) => console.log(err))
  }

  const handleUpdateStock = (comic: IComic): void => {
    // console.log(comic);
    updateStock(comic)
      .then(({ status }) => {
        console.log('status ' + status);
        if (status !== 200) {
          Swal.fire("Fail", "Fail", "error")
        }
        else {
          Swal.fire("Success", "Stock updated", "success").then(() => {
            fetchStocks();
          })
        }
      }).catch((err) => console.log(err) );
  }

  const handleDeleteStock = (comic: IComic): void => {
    if (comic._id !== undefined) {
      
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (comic._id !== undefined) {
            deleteStock(comic._id)
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error('Error! Stock not updated')
              }
              fetchStocks();
              
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
          })
          .catch((err) => console.log(err))
            Swal.fire(
              'NOT Deleted!',
              'Your file was NOT deleted.',
              'error'
            )
          }
        }
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Isle of Avalon Comics - Admin</title>
      </Helmet>
      <article>
        <h1>All Comics ({stock.length} unique,&nbsp;
          {stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number : 0), 0)} total, 
          ${formatMoney(stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number*getPriceComicB(comic, comic.batch) : 0), 0))})</h1>
        {stock && stock.map((comic: IComic) => (
          <ViewEditStockItemSwitcher
            key={comic._id}
            batches={batches}
            updateStock={handleUpdateStock}
            deleteStock={handleDeleteStock}
            comic={comic}
          />
        ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AllStockList
