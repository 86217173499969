import React from 'react'
import { Link } from 'react-router-dom'
import './AdminNavigation.css'

const AdminNavigation: React.FC = () => {

  return (
    <div className='admin-navigation'>

      <div className='admin-title'>Stock</div>
      <Link className='admin-link block' to="/admin-stock-list">All Stock</Link>
      <Link className='admin-link block' to="/admin-zero-stock-list">Zero Stock</Link>
      <div>
        <Link className='admin-link' to="/admin-new-stock-list">New Stock List</Link>
        <Link className='admin-link' to="/admin-add-new-stock-issue"><img src='/img/plus.svg' alt="plus" className='add-item-icon' /></Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-back-issue-list">Back Issue List</Link>
        <Link className='admin-link' to="/admin-add-back-issue"><img src='/img/plus.svg' alt="plus" className='add-item-icon' /></Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-games-list">Games List</Link>
        <Link className='admin-link' to="/admin-add-game"><img src='/img/plus.svg' alt="plus" className='add-item-icon' /></Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-accessories-list">Accessories List</Link>
        <Link className='admin-link' to="/admin-add-accessories"><img src='/img/plus.svg' alt="plus" className='add-item-icon' /></Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-batch-list">Batches</Link>
        <Link className='admin-link' to="/admin-add-batch"><img src='/img/plus.svg' alt="plus" className='add-item-icon' /></Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-pre-order-list">Pre-Order List</Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-ebay-check-stock">Ebay Stock Check</Link>
      </div>
      <div className='padding-top-large'>
        <Link className='admin-link' to="/admin-search-stock">Search Stock</Link>
      </div>
      
      <div>&nbsp;</div>
      <div className='admin-title'>Stock Checks</div>
      <Link className='admin-link block' to="/admin-stock-images">Images Check</Link>
      <Link className='admin-link block' to="/admin-stock-upload">Diamond Upload</Link>


      <div className='admin-title'>Orders</div>
      <Link className='admin-link block' to="/admin-orders-awaiting-postage">Orders Awaiting Postage</Link>
      <Link className='admin-link block' to="/admin-complete-order-list">Complete Orders</Link>
      <Link className='admin-link block' to="/admin-adhoc-order-list">Ad-hoc Orders</Link>
      <Link className='admin-link block' to="/admin-order-list">All Orders</Link>

      <div className='admin-title'>Other Stuff</div>
      <Link className='admin-link block' to="/admin-user-list">All Users</Link>
      <Link className='admin-link block' to="/admin-audit-history">Audit History</Link>
      <Link className='admin-link block' to="/admin-feedback">Feedback</Link>
      <Link className='admin-link block' to="/admin-status">Status</Link>
      <Link className='admin-link block' to="/admin-ebay">Ebay</Link>
    </div>
  )
}

export default AdminNavigation