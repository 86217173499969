import React, { useEffect, useState } from 'react';
import BatchDropDown from './BatchDropDown';
import { getOldComicTypeName, ItemType, OldBatchType, OldComicType, PeriodType } from '../../common';
import { getImage, getImageIdUrl } from '../../utils/ImageHandler'
import { formatEbayPriceComic, formatPriceComic, formatPriceSRPRaw } from '../../utils/MoneyFormats';
import { addStockCheck } from '../../API';
import { getMonthAndYearFromDiamondId } from '../../utils/OrderFormats';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  itemType: ItemType;
  periodType?: PeriodType;
  batchType: OldBatchType,
  batches: IBatch[] | undefined,
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,

  // options
  showSRP?: boolean, // on for NEW
  showImages?: boolean, // off for NEW
  showEbayPricing?: boolean, // on for BACK and GAMEs
  showMonth?: boolean, // on for NEW
  showYear?: boolean, // off for ACCESSORIES
}

const AddGenericItem: React.FC<Props> = ({ formComic, setFormComic, batchType, batches, handleCancel, handleFormSubmit, showSRP, showImages, showEbayPricing, showMonth, showYear }) => {

  const [images, setImages] = useState<ImageType[]>(formComic.images);
  const [comicError, setComicError] = useState<IComic>();
  
  useEffect(() => {
    setImages(formComic.images);
  }, [formComic.images])

  const triggerCheck = (theComic:IComic) => {
    console.log('doing addStockCheck');
    addStockCheck(theComic)
      // .then(({ data }) => {
      //   console.log('data.comic 200 ' + data.comic);
      //   setComicError(data.comic);
      // })
      .then(({ status, data }) => {
        console.log('status ' + status);
        if (status === 200) {
          console.log('data.comic 200 ' + data.comic);
          setComicError(data.comic);
        }
        else if (status === 201) {
          console.log('data.comic 201');
          setComicError(undefined);
        }
        else {
          console.log('data.comic ??? ' + status);
        }
      })
      .catch((err: Error) => console.log('error : ' + err))
  };

  const handleShareholderNameChange = (idx: any) => (evt: any) => {
    const newImages = images.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, imageId: evt.target.value };
    });

    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleAddShareholder = () => {
    const newImages = images.concat([{ imageId: "" }]);
    console.log(newImages.length);
    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleRemoveShareholder = (idx: any) => () => {
    const newImages = images.filter((_s, sidx) => idx !== sidx);
    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleClearImages = () => {
    setImages([]);
    const newComic = { ...formComic, comicId: '', images: [] };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleForm = (e: React.FormEvent<HTMLInputElement>, doCheck?:boolean): void => {
    if (e.currentTarget.id === 'comicId' && formComic.type === OldComicType.NEW /* batch === Diamond */) {
      const monthAndYear = getMonthAndYearFromDiamondId(e.currentTarget.value);
      
      const newComic = {
        ...formComic,
        [e.currentTarget.id]: e.currentTarget.value,
        releaseMonth: e.currentTarget.id ? monthAndYear.month : formComic.releaseMonth,
        releaseYear: e.currentTarget.id ? monthAndYear.year : formComic.releaseYear,
      };
      setFormComic(newComic);
      if (doCheck) {
        triggerCheck(newComic);
      }
    }
    else {
      const newComic = {
        ...formComic,
        [e.currentTarget.id]: e.currentTarget.value,
      }
      setFormComic(newComic);
      if (doCheck) {
        triggerCheck(newComic);
      }
    }
  }

  return (
    <div>
      <form className='comic-update' onSubmit={handleFormSubmit}>
        {comicError && comicError.comicId}
        {formComic.comicId ?
          (<div className="right"><img className='comic-item-preview-lge-cover' alt='cover' src={getImage(formComic)} /></div>) : ''}

        <input type='text' className='inline' id='mainDescription' onChange={handleForm} onBlur={() => triggerCheck(formComic)} value={formComic.mainDescription} placeholder={getOldComicTypeName(formComic.type as OldComicType) + ' Description'} />
        {(comicError?.mainDescription === formComic.mainDescription) && <div className='alert'>!</div>}<br />
        <input type='text' className='inline' id='variantDescription' onChange={handleForm} value={formComic.variantDescription} placeholder='Variant Description' /><br />
        <input type='text' className='inline' id='comicId' onChange={(event) => { handleForm(event, true); }} value={formComic.comicId} placeholder='Image ID' />
        {(comicError !== undefined && comicError.images !== undefined  && comicError.images.filter((i) => i.imageId === formComic.comicId).length >= 1) && <div className='alert'>!</div>}<br />

        <input type='text' className='inline' id='publisher' onChange={handleForm} value={formComic.publisher} placeholder='Publisher' /><br />
        <div className='comic-batchname-input inline'>
          <BatchDropDown batches={batches} batchType={batchType} comic={formComic} setComic={setFormComic} />
        </div>
        {showMonth && <input type='text' className='comic-month-input inline' id='releaseMonth' onChange={handleForm} value={formComic.releaseMonth} placeholder='Month' /> }
        {showYear && <input type='text' className='comic-year-input inline' id='releaseYear' onChange={handleForm} value={formComic.releaseYear} placeholder='Year' />}
        <h1>
          x<input type='number' className='comic-number-input inline ' id='number' onChange={handleForm} value={formComic.number} placeholder='Quantity' />
          {showSRP && (<>&nbsp;&nbsp;&nbsp;&nbsp;<span className='strikethrough'>${formatPriceSRPRaw(formComic.srp)}</span>&nbsp;&nbsp;</>)}
          $<input type='number' className='comic-price-input inline ' id='priceOverride' onChange={handleForm} value={formComic.priceOverride} placeholder='Price' />
          $<input type='number' className='comic-price-input inline ' id='pnp' onChange={handleForm} value={formComic.pnp} placeholder='P&P' /><br />
          {showEbayPricing && (<>${formatEbayPriceComic(formComic, formComic.batch.discountPercent)}&nbsp;</>)}
          [${formatPriceComic(formComic, formComic.batch.discountPercent)}]
        </h1>
        {showImages ?
          <div>
            {images.map((image, idx) => image ? (
              <div className="comic-item-preview-add-image-container" key={idx}>
                {image.imageId && <div className="right"><img className='comic-item-preview-sml-cover' alt='cover' src={getImageIdUrl(formComic, image.imageId)} /></div>}
                <div className="comic-item-preview-add-image-field inline">
                  <input
                    type="text"
                    placeholder={`Image #${idx + 1} name`}
                    value={image.imageId}
                    onChange={handleShareholderNameChange(idx)}
                  />
                </div>
                <button type="button" onClick={handleRemoveShareholder(idx)} className="small inline"> - </button>
                {(comicError && comicError.images.filter((i) => i.imageId === image.imageId).length >= 1) && (<>&nbsp;&nbsp;&nbsp;<div className='alert'>!</div></>)}
              </div>
            ) : (<></>))}
            <hr />
            <button type="button" onClick={handleAddShareholder} className="link-button add-image-button">add image</button>
            <span className='right'>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            <button type="button" onClick={handleClearImages} className="link-button clear-images-link">clear</button>
          </div> :
          <><hr /></>}
        <div>
          <button className="link-button" onClick={() => handleCancel()} type='button'>cancel</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button className="link-button" type="submit" disabled={comicError !== undefined}>save</button>
          {/* <EbayControls comic={formComic} setComic={setFormComic} setResult={setResult}/>
          {result !== '' ? (<div>{result}</div>) : ''} */}
        </div>
      </form>
    </div>
  );
}

export default AddGenericItem