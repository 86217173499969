import React, { useState } from 'react'
import BillingAddress from './BillingAddress';
import ShippingAddress from './ShippingAddress';
import SquarePaymentForm from './SquarePaymentForm';
import { updateOrderState } from '../../utils/OrderCalculations';
import './PaymentDialog.css';
import { getAdminFromStorage } from '../../utils/UserHandler';

type PaymentDialogProps = {
  order: IOrder,
  setOrder: (order: IOrder) => void;
  libraries: string[];
  transactions: ITransaction[];
  credit: number;
  handleCloseDialog: () => void;
  handleLogin: () => void;
  cardTokenizeResponseReceived: (token: any, verifiedBuyer: any) => void;
  handleCompleteOrder: () => void;
}

const PaymentDialog: React.FC<PaymentDialogProps> = ({ order, setOrder, libraries, transactions, credit, handleCloseDialog, handleLogin, cardTokenizeResponseReceived, handleCompleteOrder }) => {
  const enum STEPS { SHIPPING_ADDRESS, BILLING_ADDRESS, PAYMENT }
  const [step, setStep] = useState<STEPS>(STEPS.SHIPPING_ADDRESS);
  
  const handleShowShippingAddress = () => {
    setStep(STEPS.SHIPPING_ADDRESS);
  }

  const handleShowBillingAddress = () => {
    setStep(STEPS.BILLING_ADDRESS);
  }

  const handleShowPayment = () => {
    setStep(STEPS.PAYMENT);
  }

  const determineOrderStep = () => {
    // console.log(`determineOrderStep ` + step)
    if (step === STEPS.SHIPPING_ADDRESS) {
      return (
        <div>
          <ShippingAddress
            order={order}
            setOrder={setOrder}
            libraries={libraries}
            handleNext={handleShowBillingAddress}
            handleLogin={handleLogin}
          />
        </div>
      )
    }
    else if (step === STEPS.BILLING_ADDRESS) {
      return (
        <div>
          <BillingAddress
            order={order}
            setOrder={setOrder}
            libraries={libraries}
            handleNext={handleShowPayment}
            handleBack={handleShowShippingAddress}
          />
        </div>
      )
    }
    else if (step === STEPS.PAYMENT) {
      
      updateOrderState(order, transactions, credit, getAdminFromStorage());

      return (
        <div>
          <SquarePaymentForm
            order={order}
            shippingAddress={order.shippingAddress ? order.shippingAddress : {
              familyName: '',
              givenName: '',
              addressLine1: "",
              // addressLine2: "",
              countryCode: "AUD",
              suburb: "",
              state: "",
              postalCode: "",
            }}
            billingAddress={order.billingAddress ? order.billingAddress : {
              familyName: '',
              givenName: '',
              addressLine1: "",
              // addressLine2: "",
              countryCode: "AUD",
              suburb: "",
              state: "",
              postalCode: "",
            }}
            handleResponseReceived={cardTokenizeResponseReceived}
            handleCompleteOrder={handleCompleteOrder}
            handleChangeShippingAddress={handleShowShippingAddress}
            handleChangeBillingAddress={handleShowBillingAddress}
          />
        </div>
      )
    }
  }

  return (
    <>
      <div className='grey-background'></div>
      <div className='centerpoint'>
        <div className='modal-payment'>
          <button
            onClick={() => handleCloseDialog()}
            className='image-button dialog-close-button'
          >
            <img src='/img/x.svg' alt='delete' />
          </button>
          {determineOrderStep()}
        </div>
      </div>
    </>
  )
}

export default PaymentDialog
