import React, { useRef } from 'react';
import './SearchHistory.css'
import { useOutsideAlerter } from './general/OutsideAlerter';

type Props = {
  order: IOrder,
  handleSearchHistory: (searchTerm: string) => void,
  outsideSearchClick: () => void,
}

// const searchTerms = [
//   { name: 'Batman', image: 'batman.svg' },
//   { name: 'Batman', image: 'batman.svg' }
// ];

const SearchHistory: React.FC<Props> = ({ order, handleSearchHistory, outsideSearchClick }) => {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, outsideSearchClick);

  return (
    <>
      <div ref={wrapperRef} className='search-history-wrapper'>
        <div className='search-history'>
          {/* order.searchHistory.length > 0 && <div className='search-header'>Recent</div> */}
          {order.searchHistory.map((term, idx) => (
            <div className="search-term" key={idx} onClick={() => handleSearchHistory(term)}>
              {term}
            </div>
          ))}
          {/* order.searchHistory.length > 0 && <div className='search-header-2'>Common</div> */}
          {/* searchTerms.map((term, idx) => (
            <div className="search-term" key={idx} onClick={() => handleSearchHistory(term.name)}>
              {term.name}
            </div>
          )) */}
        </div>
      </div>
    </>
  );
};

export default SearchHistory;